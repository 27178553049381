<template lang="pug">
.course-day-item
  .course-day-item__text.course-day-item__text_city {{ city.name }}
  .course-day-item__text.course-day-item__text_date {{ courseDay.date | formattedDate }}
  .course-day-item__text.course-day-item__text_name {{ courseDay.name }}
  .course-day-item__text.course-day-item__text_meeting-space
    div(v-if="courseDay.meeting_space && courseDay.meeting_space.name")
      locationIcon(:locationType="'location'")
      span {{ courseDay.meeting_space.name }}
    div(v-else).text-label
      locationIcon(:locationType="'no-location'")
      span No meeting space
  .course-day-item__text
    course-day-crud(
      :item="courseDay"
      editable
      @click:remove="$emit('removed', courseDay.id)"
      @update="$emit('update', $event)"
      @crudOpen="$emit('crudOpen')"
    )
      template(v-slot:activator="{toggle}")
        span(@click.stop="toggle").course-day__edit.unselectable
          ico-dots-horizontal
</template>

<script>
import { TRANSPORT_TYPE } from '../../course_managment/core/cm-const'

export default {
  name: 'CourseDayItem',

  props: {
    courseDay: {
      type: Object,
      required: true
    },
    city: {
      required: true,
      type: Object
    },
    meetingSpaces: Array
  },

  data: () => ({
    TRANSPORT_TYPE
  }),

  components: {
    courseDayCrud: () => import('@/app/admin/modules/course_managment/components/curssusen/cm_configuration/CourseDayCrud.vue'),
    transportInfo: () => import("./CourseDaysTransportInfo.vue"),
    transportIcon: () => import('@/components/global/TransportIcon.vue'),
    locationIcon: () => import('@/components/global/LocationIcon.vue'),
    icoDotsHorizontal: () => import('@/assets/img/ui/components/IcoDotsHorizontal.vue')
  }
}
</script>

<style lang="scss">
.course-day-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  padding-left: 14px;
  min-height: 37px;
  width: 100%;

  &__text {
    padding: 5px 0;
    color: $title-second;
    font-weight: 500;
    font-size: 10px;

    &_city {
      width: 10%;
    }

    &_date {
      width: 10%;
    }

    &_name {
      width: 15%;
    }

    &_meeting-space {
      width: 20%;

      div {
        display: flex;
        align-items: center;

        .location-icon {
          margin-right: 5px;
          min-width: 24px;
        }

        span {
          overflow: hidden;
          max-width: 130px;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }
      }
    }

    &_transports {
      width: 35%;

      &--empty {
        margin: 0;
        color: $end-color;
        font-weight: 500;
      }
    }
  }

  &__transport-item {
    display: flex;
    align-items: center;
    margin-right: 2px;
    padding-top: 6px;
    padding-bottom: 6px;

    &:first-child {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-location {
      display: inline-block;
      overflow: hidden;
      width: 124px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__edit {
    display: flex;
    color: $primary-color;
    font-size: 16px;
  }
}

.transports--empty {
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    margin-left: 5px;
  }
}
</style>
